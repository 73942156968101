import styled from 'styled-components';

const SubTitle = styled.h4`
  font-size: 2.2rem;
  max-width: ${({ half }) => half && '50%'};
  line-height: 1.4;
  margin-bottom: ${({ smallerMb }) => (smallerMb ? '6rem' : '8rem')};
  margin: ${({ m }) => m && m};

  @media screen and (max-width: 767px) {
    margin-bottom: 4rem;
    max-width: 100%;
  }
`;

export default SubTitle;

import styled, { css } from 'styled-components';

const ColoredBox = styled.div`
  background-color: ${({ theme, transparent }) =>
  transparent ? 'transparent' : theme.colors.main};
  padding: 5rem 7rem;
  max-width: ${({ mw }) => (mw ? mw : '100%')};
  max-height: ${({ mh }) => mh && mh};

  ${({ icon }) =>
  icon &&
  css`
      padding: 5rem;
      width: ${({ width }) => (width ? width : '100%')};
      height: ${({ height }) => height && height};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      
      @media screen and (max-width: 575px) {
        margin: 0 auto 2rem;
        padding: 6rem;
      }
    `}

  @media screen and (max-width: 767px) {
    padding: 2rem;
  }
`;

export default ColoredBox;
import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';

const StyledWrapper = styled.div`
  margin: ${({ m }) => (m ? m : '0 auto')};
  width: ${({ width }) => width && width};

  @media screen and (max-width: 767px) {
    margin: 4rem 0;
  }
`;

const StyledImage = styled(Image)`
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;

  img {
    width: 100%;
    height: 100%;
  }
`;

const ImageWrapper = ({ image, alt, m, width, imgStyle, ...props }) => {
  return (
    <StyledWrapper m={m} width={width} {...props}>
      <StyledImage fluid={image} alt={alt} imgStyle={imgStyle} />
    </StyledWrapper>
  );
};

export default ImageWrapper;

import styled, { css } from 'styled-components';

const InnerBox = styled.div`
  max-width: 80%;
  padding-left: ${({ pl }) => pl && '7rem'};

  ${({ bigPadding }) =>
    bigPadding &&
    css`
      padding: 2rem 7rem;

      @media screen and (max-width: 1500px) {
        padding-right: 0;
      }
    `}

  @media screen and (max-width: 1199px) {
    max-width: ${({ mw }) => (mw ? '100%' : '85%')};
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
    padding: 0;
  }
`;

export default InnerBox;

import styled from 'styled-components';

const Label = styled.h3`
  margin-bottom: 5rem;
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  line-height: 1.4;
  
  @media screen and (max-width: 767px) {
    text-align: left;
  }
`;

export default Label;
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = styled.h2`
  display: inline-block;
  font-size: ${({ small }) => (small ? '6rem' : '15rem')};
  font-family: ${({ theme }) => `${theme.fonts.second}, sans-serif`};
  font-weight: 600;
  line-height: 15rem;
  margin: 0;
  padding: 0;
  text-align: center;

  ${({ section }) =>
    section &&
    `
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
    `};

  top: ${({ top }) => top};
  margin-bottom: ${({ mb }) => mb};
  user-select: none;

  @media screen and (max-width: 1200px) {
    font-size: ${({ small }) => (small ? '6rem' : '13rem')};
  }

  @media screen and (max-width: 991px) {
    font-size: ${({ small }) => (small ? '6rem' : '10rem')};
    line-height: 10rem;
  }

  @media screen and (max-width: 767px) {
    font-size: ${({ small }) => (small ? '5.2rem' : '65px')};
    line-height: 60px;
  }
`;

const TitleH1 = styled.h1`
  display: inline-block;
  font-size: ${({ small }) => (small ? '6rem' : '15rem')};
  font-family: ${({ theme }) => `${theme.fonts.second}, sans-serif`};
  font-weight: 600;
  line-height: 15rem;
  margin: 0;
  padding: 0;
  text-align: center;

  ${({ section }) =>
    section &&
    `
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
    `};

  top: ${({ top }) => top};
  margin-bottom: ${({ mb }) => mb};
  user-select: none;

  @media screen and (max-width: 1200px) {
    font-size: ${({ small }) => (small ? '6rem' : '13rem')};
  }

  @media screen and (max-width: 991px) {
    font-size: ${({ small }) => (small ? '6rem' : '10rem')};
    line-height: 10rem;
  }

  @media screen and (max-width: 767px) {
    font-size: ${({ small }) => (small ? '5.2rem' : '65px')};
    line-height: 60px;
  }
`;
const BigTitle = ({ children, className, section, top, mb, small, h1 }) => {
  return (
    <>
      {h1 ? (
        <TitleH1
          className={className}
          section={section}
          top={top}
          mb={mb}
          small={small}
        >
          {children}
        </TitleH1>
      ) : (
        <Title
          className={className}
          section={section}
          top={top}
          mb={mb}
          small={small}
        >
          {children}
        </Title>
      )}
    </>
  );
};

BigTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  section: PropTypes.bool,
  top: PropTypes.string,
  mb: PropTypes.string,
  className: PropTypes.string,
};

BigTitle.defaultProps = {
  section: false,
  top: '20%',
  mb: '0',
  className: '',
};

export default BigTitle;

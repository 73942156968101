import styled, { css } from 'styled-components';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';
import BigTitle from 'src/components/shared/text/BigTitle';
import { Wrapper } from 'src/components/Sites/ServicesPages/shared/ServicesPages';

export const HeaderSection = styled(Wrapper)`
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  @media screen and (max-width: 767px) {
    display: block;
    padding: 120px 0 0 0;
  }
`;

export const HeaderTitle = styled.h1`
  line-height: 1;
  text-align: left;
  margin-bottom: 6rem;
  font-family: ${({ theme }) => `${theme.fonts.main}, sans-serif`};
  display: inline-block;
  font-size: ${({ small }) => (small ? '6rem' : '15rem')};
  font-weight: 700;
  padding: 0;
  top: ${({ top }) => top};
  user-select: none;

  @media screen and (max-width: 1200px) {
    font-size: ${({ small }) => (small ? '6rem' : '13rem')};
  }

  @media screen and (max-width: 991px) {
    font-size: ${({ small }) => (small ? '6rem' : '10rem')};
  }

  @media screen and (max-width: 767px) {
    font-size: ${({ small }) => (small ? '5.2rem' : '65px')};
  }
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-right: 3rem;

  @media screen and (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 4rem;
  }
`;

export const HeaderParagraph = styled(ParagraphInfo)`
  font-size: 2.5rem;
  line-height: 1.5;

  ${({ big }) =>
    big &&
    css`
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 4rem;
    `};

  font-weight: ${({ regular }) => regular && 400};

  @media screen and (max-width: 767px) {
    font-size: 2.5rem;
    line-height: 1.3 !important;
  }
`;

import React, { useRef, useEffect, useContext, useState } from 'react';
import Layout from 'src/layouts/Layout';
import Section from 'src/components/shared/wraps/Section/Section';
import SEO from 'src/components/seo';
import styled, { css } from 'styled-components';
import BigTitle from 'src/components/shared/text/BigTitle';
import {
  HeaderParagraph,
  HeaderSection,
  HeaderTextWrapper,
} from 'src/components/Sites/ServicesPages/shared/Header';
import SocialMedia from 'src/components/SocialMedia/SocialMedia';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import { graphql } from 'gatsby';
import {
  MainSection,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import ColoredBox from 'src/components/Sites/ServicesPages/shared/ColoredBox';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import Paragraph from 'src/components/Sites/ServicesPages/shared/Paragraph';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import opennessIcon from 'src/assets/images/about-makadu/otwartosc.svg';
import topicalityIcon from 'src/assets/images/about-makadu/aktualnosc.svg';
import minimalismIcon from 'src/assets/images/about-makadu/minimalizm.svg';
import hardWorkIcon from 'src/assets/images/about-makadu/praca.svg';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import FadeLink from 'src/components/shared/navigations/FadeLink/FadeLink';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
  RotatingLetter,
  NoWrap,
} from 'src/components/shared/Animations/Animations';
import Image from 'gatsby-image';
import { ScrollbarContext } from 'src/layouts/Layout';
import useLng from "../hooks/useLng";

gsap.registerPlugin(ScrollTrigger);

const StyledHeaderSection = styled(HeaderSection)`
  padding-top: 0;
`;

const StyledBigTitle = styled(BigTitle)`
  text-align: center;
  width: 100%;
  margin-bottom: 7rem;
`;

const StyledSocialMedia = styled(SocialMedia)`
  position: absolute;
  left: 5rem;
  top: 7rem;
  padding: 4rem 7rem 0 0;

  @media screen and (max-width: 1199px) {
    left: 3rem;
  }

  @media screen and (max-width: 991px) {
    left: 2rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledImageWrapper = styled.div`
  max-width: 50vw;
  width: 100%;
  height: 50rem;
  flex-shrink: 0;
  position: relative;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    height: 35rem;
  }
`;

const LogoImage = styled(Image)`
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 32.8rem;
  height: 10.7rem;
  opacity: 0;

  img {
    height: 100% !important;
    width: auto !important;
    object-fit: inherit;
  }
`;

const StyledQuoteWrapper = styled.div`
  position: relative;

  margin: ${({ m }) => m && m};

  &::before {
    content: '“';
    position: relative;
    top: 3rem;
    font-size: 12rem;
    font-family: ${({ theme }) => `${theme.fonts.second}, sans-serif`};
    font-weight: 500;
    line-height: 1;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
  }
`;

const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;

  ${({ grayBg }) =>
    grayBg &&
    css`
      background-color: ${({ theme }) => theme.colors.gray2};

      @media screen and (max-width: 767px) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      margin-bottom: 0;
    `}

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  margin-bottom: ${({ noMb }) => noMb && 0};

  @media screen and (max-width: 1199px) {
    display: block;
    padding: 0;
  }
`;

const StyledColoredBox = styled(ColoredBox)`
  padding-left: 0;
  margin-bottom: 2rem;
  padding-top: ${({ noPt }) => noPt && 0};

  ${({ secondary }) =>
    secondary &&
    css`
      margin-bottom: 0;
      padding-bottom: 0;

      ${StyledInnerWrapper} {
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
          padding-bottom: 4rem;
        }
      }

      ${SubTitle} {
        margin-bottom: 0;
      }
    `}

  @media screen and (max-width: 1199px) {
    padding-left: 7rem;
  }

  @media screen and (max-width: 767px) {
    padding-left: 2rem;
  }
`;

const StyledImageIconWrapper = styled.div`
  margin: 0 auto 2rem;
  width: 11rem;

  @media screen and (max-width: 575px) {
    margin: 0;
    width: max(7rem, 14vw);
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

const IconsWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;

  ${ColoredBox} {
    flex-basis: 25%;
    justify-content: space-between;
    margin-bottom: 0;

    ${ParagraphInfo} {
      text-align: center;
    }
  }

  @media screen and (max-width: 767px) {
    ${ColoredBox} {
      justify-content: center;
      height: max(26vw, 12rem) !important;
    }
  }

  @media screen and (max-width: 575px) {
    flex-wrap: wrap;

    ${ColoredBox} {
      flex-basis: 50%;
      justify-content: space-between;
      height: max(50vw - 40px, 12rem) !important;

      &:nth-last-child(2) {
        background-color: transparent;
      }

      &:last-child {
        background-color: ${({ theme }) => theme.colors.main};
      }
    }
  }
`;

const StyledInnerBox = styled(InnerBox)`
  width: 65%;
  min-width: 34rem;
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 2.2rem;

  span {
    font-size: 8rem;
    line-height: 1;
  }
`;

const StyledFadeLink = styled(FadeLink)`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: underline;
  margin-left: -0.8rem;
`;

const PageWrapper = ({ handleScrollbar, children }) => {
  const { scrollbar } = useContext(ScrollbarContext);

  useEffect(() => {
    handleScrollbar(scrollbar);
  }, [scrollbar]);

  return <div>{children}</div>;
};

const About = ({
  location: { pathname: path },
  transitionStatus,
  entry,
  exit,
  data,
}) => {
  const opennessRef = useRef(null);
  const minimalismRef = useRef(null);
  const logoRef = useRef(null);
  const [scrollbar, setScrollbar] = useState(null);

  const handleScrollbar = scrollbar => {
    setScrollbar(scrollbar);
  };

  const headingImage = data.allFile.nodes.find(
    ({ name }) => name === 'header-image',
  ).childImageSharp.fluid;

  const logo = data.allFile.nodes.find(({ name }) => name === 'logo')
    .childImageSharp.fluid;

  const ownerImage = data.allFile.nodes.find(
    ({ name }) => name === 'wlasciciel',
  ).childImageSharp.fluid;

  useEffect(() => {
    if (scrollbar) {
      [opennessRef, minimalismRef].forEach((node, index) => {
        gsap.fromTo(
          node.current,
          { autoAlpha: 0, y: index === 0 ? -80 : 80 },
          {
            scrollTrigger: {
              trigger: opennessRef.current,
              start: 'top 70%',
              end: 'top 40%',
              scrub: 1,
              scroller: scrollbar.contentEl,
            },
            autoAlpha: 1,
            y: 0,
          },
        );
      });

      gsap.to(logoRef.current.base, { autoAlpha: 1, duration: 2, delay: 2 });
    }
  }, [scrollbar]);

  const {t} = useLng()

  return (
    <Layout
      path={path}
      id="o-makadu"
      nonBgc
      transitionStatus={transitionStatus}
      entry={entry}
      exit={exit}
    >
      <SEO title={t("aboutSeo")} />
      <PageWrapper handleScrollbar={handleScrollbar}>
        <Section nonGrid nonFlex first>
          <StyledBigTitle>
            {t("about")}
            <NoWrap>
              Makad<RotatingLetter delay={0.4}>u</RotatingLetter>
            </NoWrap>
          </StyledBigTitle>
        </Section>
        <Section nonGrid nonFlex>
          <StyledHeaderSection pl>
            <HeaderTextWrapper>
              <StyledSocialMedia />
              <StyledQuoteWrapper>
                <HeaderParagraph big regular>
                  {t("about1")}
                </HeaderParagraph>
              </StyledQuoteWrapper>
            </HeaderTextWrapper>
            <StyledImageWrapper>
              <Image style={{ height: '100%' }} fluid={headingImage} />
              <LogoImage ref={logoRef} fluid={logo} />
            </StyledImageWrapper>
          </StyledHeaderSection>
        </Section>
        <MainSection>
          <StyledWrapper mw secondary>
            <StyledColoredBox secondary>
              <StyledInnerWrapper pl>
                <SubTitle smallerMb>{t("about2")}</SubTitle>
              </StyledInnerWrapper>
            </StyledColoredBox>
          </StyledWrapper>
          <StyledWrapper mw>
            <div>
              <StyledColoredBox noPt>
                <StyledInnerWrapper pl noMb>
                  <Paragraph small m="0 !important">
                    {t("about3")}
                  </Paragraph>
                </StyledInnerWrapper>
              </StyledColoredBox>
              <StyledInnerWrapper pl>
                <Paragraph small>
                  {t("about4")}
                </Paragraph>
              </StyledInnerWrapper>
            </div>
            <InnerBox pl mw>
              <Paragraph small>
                {t("about5")}
              </Paragraph>
              <Paragraph small>
                {t("about6")}
              </Paragraph>
              <Paragraph small>
                {t("about7")}

              </Paragraph>
            </InnerBox>
          </StyledWrapper>
        </MainSection>
        <MainSection>
          <Wrapper nonGrid>
            <Label>{t("about_7_2")}</Label>
            <Label>
              {t("about8")}
            </Label>
          </Wrapper>
          <IconsWrapper>
            <ColoredBox evenly icon height="28rem" ref={opennessRef}>
              <StyledImageIconWrapper>
                <StyledImage alt="otwartość" src={opennessIcon} />
              </StyledImageIconWrapper>
              <ParagraphInfo title>{t("about9")}</ParagraphInfo>
            </ColoredBox>
            <ColoredBox evenly transparent icon height="28rem">
              <StyledImageIconWrapper>
                <StyledImage alt="aktualność" src={topicalityIcon} />
              </StyledImageIconWrapper>
              <ParagraphInfo title>{t("about10")}</ParagraphInfo>
            </ColoredBox>
            <ColoredBox evenly icon height="28rem" ref={minimalismRef}>
              <StyledImageIconWrapper>
                <StyledImage alt="minimalizm" src={minimalismIcon} />
              </StyledImageIconWrapper>
              <ParagraphInfo title>{t("about11")}</ParagraphInfo>
            </ColoredBox>
            <ColoredBox evenly transparent icon height="28rem">
              <StyledImageIconWrapper>
                <StyledImage alt="ciężka praca" src={hardWorkIcon} />
              </StyledImageIconWrapper>
              <ParagraphInfo title>{t("about12")}</ParagraphInfo>
            </ColoredBox>
          </IconsWrapper>
        </MainSection>
        <MainSection>
          <StyledWrapper mw grayBg>
            <StyledInnerWrapper pl>
              <StyledInnerBox>
                <ImageWrapper image={ownerImage} alt="Właściciel Makadu" />
              </StyledInnerBox>
            </StyledInnerWrapper>
            <InnerBox>
              <StyledQuoteWrapper m="2rem 0 8rem">
                <Paragraph>
                  {t("about13")}
                </Paragraph>
              </StyledQuoteWrapper>
            </InnerBox>
          </StyledWrapper>
        </MainSection>
        <MainSection>
          <Wrapper nonGrid>
            <StyledParagraph>
              {t("about14")} <span>180</span>{' '}
              {t("about15")}
            </StyledParagraph>
            <StyledParagraph>
              <StyledFadeLink stylish to="/projekty" hover>
                {t("zobaczRealizacje")}
              </StyledFadeLink>
            </StyledParagraph>
          </Wrapper>
        </MainSection>
      </PageWrapper>
    </Layout>
  );
};

export const aboutMakaduQuery = graphql`
  query AboutMakaduQuery {
    allFile(filter: { relativeDirectory: { eq: "about-makadu" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export default About;
